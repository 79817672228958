import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import requestHandler from "../utilities/requestHandler";
import Images from "../utilities/images"

const Header = () => {

  const [loggedIn, setLoggedIn] = useState(false)
  const [userData, setUserData] = useState([])
  const [logoUrl, setLogoUrl] = useState(null);


  const checkIfLoggedIn = async () => {
    let x = await requestHandler.get('auth/logged/user', true);
    if(x !== null && x.status === 1){
        let y = x.message;
        setLoggedIn(true)
        
    }
  }

const userProfile = async () => {
  let x = await requestHandler.get('user/profile', true);
  if(x != null && x.status === 1){
      let y = x.message;
      setUserData(y[0])
      
  }
}

const websiteSettings = async () => {
  let x = await requestHandler.get('settings/get', false);
  if(x != null && x.status === 1){
      let y = x.message;
      setLogoUrl(x.message.logo)
  }
}


useEffect(()=>{
  websiteSettings()
  checkIfLoggedIn()
userProfile()
},[]);

    return (
        <div id="nav" class="nav-container d-flex">
        <div class="nav-content d-flex">

          <div class="logo position-relative">
            <a href="/">

            <div style={{display: logoUrl !== null && logoUrl !== ""? `block` : `none`}} class="sw-13 position-relative">
    <img src={logoUrl} className="img-fluid rounded-xl" style={{maxWidth: 75, maxHeight: 35}}/>
    </div>
            </a>
          </div>

          <div class="user-container d-flex">
            <div style={{display: loggedIn? 'flex' : 'none'}}>
            <a href="#" class="d-flex user position-relative" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <img class="profile" alt="profile" src={userData.picture !== null? userData.picture :  Images().profileImage} />
              <div class="name">{userData.first_name}</div>
            </a>
            <div class="dropdown-menu dropdown-menu-end user-menu wide">
              
              <div class="row mb-1 ms-0 me-0">
                <div class="col-6 ps-1 pe-1">
                  <ul class="list-unstyled">
                    <li>
                      <Link to="/user/profile">
                        <i data-cs-icon="user" class="me-2" data-cs-size="17"></i>
                        <span class="align-middle">Profile</span>
                      </Link>
                    </li>
                  </ul>
                </div>
                <div class="col-6 pe-1 ps-1">
                  <ul class="list-unstyled">
                    <li>
                      <Link to="/auth/logout">
                        <i data-cs-icon="logout" class="me-2" data-cs-size="17"></i>
                        <span class="align-middle">Logout</span>
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            </div>
          </div>

          <ul class="list-unstyled list-inline text-center menu-icons">
            <li class="list-inline-item">
              <a href="#" data-bs-toggle="modal" data-bs-target="#searchPagesModal">
                <i data-cs-icon="search" data-cs-size="18"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a href="#" id="colorButton">
                <i data-cs-icon="light-on" class="light" data-cs-size="18"></i>
                <i data-cs-icon="light-off" class="dark" data-cs-size="18"></i>
              </a>
            </li>
            <li class="list-inline-item">
          <Link to={"/auth/logout"} style={{display: loggedIn? 'flex' : 'none'}}>
          <i data-cs-icon="power" data-cs-size="18"></i>
            </Link>
            </li>
            <li class="list-inline-item">
          <Link to={"/auth/login"} style={{display: !loggedIn? 'flex' : 'none'}}>
          <i data-cs-icon="login" data-cs-size="18"></i>
            </Link>
            </li>
            {/*<li class="list-inline-item">
              <a href="#" data-bs-toggle="dropdown" data-bs-target="#notifications" aria-haspopup="true" aria-expanded="false" class="notification-button">
                <div class="position-relative d-inline-flex">
                  <i data-cs-icon="bell" data-cs-size="18"></i>
                  <span class="position-absolute notification-dot rounded-xl"></span>
                </div>
              </a>
              <div class="dropdown-menu dropdown-menu-end wide notification-dropdown scroll-out" id="notifications">
                <div class="scroll">
                  <ul class="list-unstyled border-last-none">
                    <li class="mb-3 pb-3 border-bottom border-separator-light d-flex">
                      <img src="img/profile/profile-1.jpg" class="me-3 sw-4 sh-4 rounded-xl align-self-center" alt="..." />
                      <div class="align-self-center">
                        <a href="#">Joisse Kaycee just sent a new comment!</a>
                      </div>
                    </li>
                    <li class="mb-3 pb-3 border-bottom border-separator-light d-flex">
                      <img src="img/profile/profile-2.jpg" class="me-3 sw-4 sh-4 rounded-xl align-self-center" alt="..." />
                      <div class="align-self-center">
                        <a href="#">New order received! It is total $147,20.</a>
                      </div>
                    </li>
                    <li class="mb-3 pb-3 border-bottom border-separator-light d-flex">
                      <img src="img/profile/profile-3.jpg" class="me-3 sw-4 sh-4 rounded-xl align-self-center" alt="..." />
                      <div class="align-self-center">
                        <a href="#">3 items just added to wish list by a user!</a>
                      </div>
                    </li>
                    <li class="pb-3 pb-3 border-bottom border-separator-light d-flex">
                      <img src="img/profile/profile-6.jpg" class="me-3 sw-4 sh-4 rounded-xl align-self-center" alt="..." />
                      <div class="align-self-center">
                        <a href="#">Kirby Peters just sent a new message!</a>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </li>*/}
          </ul>

          <div class="menu-container flex-grow-1">
            <ul id="menu" class="menu">
            <li>
                <Link to="/about">
                  <i data-cs-icon="question-circle" class="icon" data-cs-size="18"></i>
                  <span class="label">About</span>
                </Link>
          </li>
              {<li>
                <Link to="/faq">
                  <i data-cs-icon="question-hexagon" class="icon" data-cs-size="18"></i>
                  <span class="label">FAQ</span>
                </Link>
          </li>}
             {/* <li>
                <Link to="/auth/login" style={{display: !loggedIn? 'flex' : 'none'}}>
                  <i data-cs-icon="login" class="icon" data-cs-size="18"></i>
                  <span class="label">Log in</span>
                </Link>
              </li>
              <li>
                <Link to="/auth/register" style={{display: !loggedIn? 'flex' : 'none'}}>
                  <i data-cs-icon="user-plus" class="icon" data-cs-size="18"></i>
                  <span class="label">Register</span>
                </Link>
        </li> */}
            </ul>
          </div>

          <div class="mobile-buttons-container">
            <a href="#" id="mobileMenuButton" class="menu-button">
              <i data-cs-icon="menu"></i>
            </a>
          </div>
        </div>
        <div class="nav-shadow"></div>
      </div>
    )
}
export default Header;