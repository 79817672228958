import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"
import requestHandler from "../../utilities/requestHandler"
import Loading from "../../utilities/Loading"
import moneyFormat from "../../utilities/moneyFormat"

const UserOverview = () => {
    const [pageTile, setPageTitle] = useState("Activities Overview")
    const [contentsShared, setContentsShared] = useState(0)
    const [shared, setShared] = useState(0)
    const [viewed, setViewed] = useState(0)
    const [allViews, setAllViews] = useState(0)
    const [country, setCountry] = useState(null)
    const [currentBalance, setCurrentBalance] = useState(0)
    const [withdrawn, setWithdrawn] = useState(0)
    const [referrals, setReferrals] = useState(0)
    const [referralEarnings, setReferralEarnings] = useState(0)
    const [isLoading, setLoading] = useState(false)

    const checkIfLoggedIn = async () => {
      let x = await requestHandler.get('auth/logged/user', true);
      if(x != null && x.status === 0){
          window.location.href = "/auth/login/redirect"
      }
    }

    const userProfile = async () => {
      let x = await requestHandler.get('user/profile', true);
      if(x != null && x.status === 1){
          let y = x.message[0];
          setCountry(y.country)
      }
    }

    const userStats = async () => {
      setLoading(true)
        let x = await requestHandler.get('user/stats', true);
        if(x != null && x.status === 1){
            let y = x.message[0];
            //setUserData(y)
            setShared(y.shares)
            setViewed(y.clicks)
            setAllViews(y.total_clicks)
            setContentsShared(x.shares)
            setReferrals(x.referrals)
            setCurrentBalance(y.main_wallet)
            setReferralEarnings(y.referral_commission)
            setWithdrawn(y.withdrawn)
        }
        setLoading(false)
      }
    
    useEffect(() => {
      checkIfLoggedIn()
      userProfile()
        document.title = pageTile
        userStats()
    },[pageTile])

    return(
        <div className="col">
        <h2 class="small-title">Overview</h2>

        {isLoading? <Loading/> : ''}
                <div class="mb-5">
                  
                  <div class="row g-2">
                    
                    <div class="col-12 col-sm-6 col-lg-6">
                      <div class="card hover-border-primary">
                        <div class="card-body">
                          <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                            <span>CURRENT BALANCE</span>
                            <i className="fa-regular fa-wallet text-primary"></i>
                          </div>
                          <div class="text-small text-muted mb-1">Total</div>
                          <div class="cta-1 text-primary">{moneyFormat(currentBalance, country)}</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-6 col-lg-6">
                      <div class="card hover-border-primary">
                        <div class="card-body">
                          <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                            <span>REFERRAL EARNINGS</span>
                            <i className="fa-regular fa-money-bills text-primary"></i>
                          </div>
                          <div class="text-small text-muted mb-1">Total</div>
                          <div class="cta-1 text-primary">{moneyFormat(referralEarnings, country)}</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-6 col-lg-6">
                      <div class="card hover-border-primary">
                        <div class="card-body">
                          <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                            <span>WITHDRAWN</span>
                            <i className="fa-regular fa-money-check-dollar text-primary"></i>
                          </div>
                          <div class="text-small text-muted mb-1">Total</div>
                          <div class="cta-1 text-primary">{moneyFormat(withdrawn, country)}</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-6 col-lg-6">
                      <div class="card hover-border-primary">
                        <div class="card-body">
                          <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                            <span>REFERRALS </span>
                            <i className="fa-regular fa-users text-primary"></i>
                          </div>
                          <div class="text-small text-muted mb-1">Total</div>
                          <div class="cta-1 text-primary">{referrals}</div>
                        </div>
                      </div>
                    </div>

                    <div class="col-12 col-sm-6 col-lg-6">
                      <div class="card hover-border-primary">
                        <div class="card-body">
                          <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                            <span>CONTENTS SHARED</span>
                            <i className="fa-regular fa-share-nodes text-primary"></i>
                          </div>
                          <div class="text-small text-muted mb-1">Total</div>
                          <div class="cta-1 text-primary">{contentsShared}</div>
                        </div>
                      </div>
                    </div>
                    
                    <div class="col-12 col-sm-6 col-lg-6">
                      <div class="card hover-border-primary">
                        <div class="card-body">
                          <div class="heading mb-0 d-flex justify-content-between lh-1-25 mb-3">
                            <span>CONTENTS VIEWED</span>
                            <i className="fa-regular fa-laptop text-primary"></i>
                          </div>
                          <div class="text-small text-muted mb-1">Total</div>
                          <div class="cta-1 text-primary">{viewed}</div>
                        </div>
                      </div>
                    </div>

                    
                  </div>

                </div>
        </div>  
    )
}

export default UserOverview;