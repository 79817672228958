import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"
import { Link, useNavigate } from "react-router-dom"
import requestHandler from "../../utilities/requestHandler"
import { ToastContainer, toast } from 'react-toastify';
import Loading from "../../utilities/Loading"
import Images from "../../utilities/images"
import countriesAndDialCodes from "../../utilities/countries"
import moneyFormat from "../../utilities/moneyFormat"

const PurchaseCoupon = () => {

    const navigation = useNavigate();
    const [pageTile, setPageTitle] = useState("Purchase Coupon")
    const [country, setCountry] = useState(localStorage.getItem('country'));
    const [officialEmail, setOfficialEmail] = useState(null)
    const disclaimer = "Kindly note that Vendors are like other users and they are not affiliated to us, but given the task of selling coupon codes. Kindly report any vendor that you are not satisfied with.";
    const [isLoading, setLoading] = useState(false)
    const [vendors, setVendors] = useState([])

    const websiteSettings = async () => {
      let x = await requestHandler.get('settings/get', false);
      if(x != null && x.status === 1){
          let y = x.message;
          setOfficialEmail(y.email)
      }
  }

    useEffect(() => {
      getVendors()
      websiteSettings()
        document.title = pageTile
    },[pageTile])



    const getVendors = async () => {
        setLoading(true)

        if(country != null){            
        let x = await requestHandler.post('vendor/search_by_country', {country: country}, false, false)
        if(x != null && x.status === 1){
            setVendors(x.message)
        }
        else if(x != null && x.status === 0){
            toast.error(x.message)
        }
        else{
            toast.error('Something went wrong')
        }
        }
        setLoading(false)
    }

    return(
     
      <div class="row justify-content-center align-items-center">
<div class="col-md-6 col-lg-6 col-xl-6">        
                  <form className="card tooltip-end-top" novalidate>
                    <div className="card-body">
                    <h1>Vendors</h1>
                    <p className="text-alternate mb-4">{disclaimer}</p>
                    <p className="text-alternate mb-4"><b>Email: {officialEmail}</b></p>
                    <p className="text-alternate mb-4"><b>Coupon Price: {moneyFormat(5, country)}</b></p>
                    
                    {isLoading? <Loading/> : ''}
                    
                     {/* <div className="mb-3 filled">
                        <select onChange={e => setCountry(e.target.value)} class="form-control" data-width="100%" id="">
                            <option>Select</option>
                          {
                            countries.map((x) => {
                            return (<option value={x.country}>{x.country}</option>)  
                            })
                        }
                        </select>
                      </div>
                      
                      <div>
                        {isLoading? <Loading/> : 
                        <button onClick={getVendors} className="btn btn-icon btn-icon-end btn-primary" type="button">
                          <span>Search</span>
                          <i data-cs-icon="chevron-right"></i>
                        </button>}
                      </div>*/}
                      
                    </div>
                     <div style={{display: vendors.length > 0? 'flex' : 'none'}} className="row">
                        <div className="table-responsive">
                      <table className="table table-striped">
                        <thead>
                            <tr>
                            <td>Name</td>
                            <td>Phone</td>
                            </tr>
                        </thead>
                        <tbody>
                        {vendors.map(x => <tr>
                            <td>{x.first_name} {x.last_name}</td>
                            <td>{x.phone}</td>
                            </tr>)}
                        </tbody>
                      </table>
                      </div>
                      </div>
                      

                    
                  </form>  
                      </div>

                <div class="col-md-6 col-lg-6 col-xl-6 d-none d-lg-block">  
                <img src={Images().landingImage}
          class="img-fluid" alt="Sample image" />
                </div>
                </div>
    )
}

export default PurchaseCoupon;