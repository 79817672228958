import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"
import { toast } from "react-toastify"
import requestHandler from "../../utilities/requestHandler"
import Loading from "../../utilities/Loading"
import Images from "../../utilities/images"
import { Link } from "react-router-dom"

const Influencers = () => {

    const [pageTile, setPageTitle] = useState("Users")
    const [isLoading, setLoading] = useState(false)
    const [users, setUsers] = useState([])
    const [constUsers, setConstUsers] = useState(20)
    const [search, setSearch] = useState(null)

    const checkIfLoggedIn = async () => {
        setLoading(true)
        let x = await requestHandler.get('auth/logged/user', true);
        if(x != null && x.status === 0){
            window.location.href = "/auth/login/redirect"
        }
        setLoading(false)
      }

      const searchUsers = async () => {
        setLoading(true)
        let x = await requestHandler.get('admin/users/'+search+'/search', true);
        if(x != null && x.status === 1){
            setUsers(x.message)
            toast.success("New result found")
        }
        else{
            toast.error(x.message)
        }
        setLoading(false)
      }

      const onSearchEnter = (event) =>{
        if(event.keyCode === 13){
          searchUsers()
        }
      }

      const getUsers = async () => {
        setLoading(true)
        let x = await requestHandler.get('admin/'+constUsers+'/users', true);
        if(x != null && x.status === 1){
            setUsers(x.message)
            //console.log(x.message)
        }
        else{
            toast.error(x.message)
        }
        setLoading(false)
      }

      const getMoreUsers = async () => {
        setLoading(true)
        let newConstUsers = constUsers + 20;
        setConstUsers(newConstUsers)
        let x = await requestHandler.get('admin/'+newConstUsers+'/users', true);
        if(x != null && x.status === 1){
            setUsers(x.message)
            //console.log(x.message)
        }
        else{
            toast.error(x.message)
        }
        setLoading(false)
      }  
    
    useEffect(() => {
        document.title = pageTile
        getUsers()
        
    },[pageTile])

    return(
        <div className="col">

                      
        
        <div class="col-xl-12 mb-5">
                    <section class="scroll-section" id="userButtons">
                      <h2 class="small-title">Users</h2>
                      {isLoading? <Loading/> : ''}

                      
                      <div class="col-xl-12 mb-1">
              <div class="me-1 mb-2 search-input-container shadow bg-foreground">
                <input class="form-control" placeholder="Search Influencer" value={search} onChange={e => setSearch(e.target.value)} onKeyDown={e => onSearchEnter(e)} />
                <button onClick={searchUsers} class="search-magnifier-icon btn btn-sm btn-primary">
                  <i className="fa-regular fa-search text-center"></i>
                </button>
              </div>
              </div>

                      <div class="card h-100-card">
                        <div class="card-body mb-n2 border-last-none h-100">

                        
                          
                        {users.map(x => <div class="border-bottom border-separator-light mb-2 pb-2">
                            <div class="row g-0 sh-6">
                              <div class="col-auto">
                              <img src={x.picture !== null? x.picture :  Images().profileImage} class="card-img rounded-xl sh-6 sw-6" alt="thumb" />
                              </div>
                              <div class="col">
                                <div class="card-body d-flex flex-row pt-0 pb-0 ps-3 pe-0 h-100 align-items-center justify-content-between">
                                  <div class="d-flex flex-column">
                                    <div>{x.first_name} {x.last_name}</div>
                                    <div class="text-small text-muted">{x.account_type} | {x.email}</div>
                                  </div>
                                  <div class="d-flex">
                                    <Link to={'/admin/user/'+x.id+'/'+x.email} class="btn btn-outline-secondary btn-sm ms-1" type="button">View</Link>
                                    {x.status != 1? <button class="btn btn-sm btn-danger ms-1" type="button">
                                      Blocked
                                    </button> : ''}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        )}
                          
                          </div>
                      </div>
                    </section>
                  </div>
                  {isLoading? <Loading/> 
        : 
        <div style={{display: users.length > 20? 'flex' : 'none'}} class="row mb-5">
          <button onClick={getMoreUsers} className="btn btn-primary btn-clock btn-lg">Load More</button>
        </div>
        }
        </div>  
    )
}

export default Influencers;