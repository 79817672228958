const Images = () => {

    const bgImage = require('../images/hero.jpeg');
    const bgBlank = require('../images/blank_bg.jpeg');
    const profileImage = require('../images/profile.jpg');
    const landingImage = require('../images/Cpromoter.png');

    return {bgImage: bgImage, profileImage: profileImage, bgBlank: bgBlank, landingImage}
}

export default Images
