const moneyFormat = (x, country) => {
    let defaultSymbol = "$"
    let rate = 1
    try{
    if(country === "Kenya"){ rate = 150; defaultSymbol = "Ks";}
    else if(country === "Zambia"){ rate = 20; defaultSymbol = "K";}
    else if(country === "Namibia"){rate = 20; defaultSymbol = "N$"}
    else if(country === "Zimbabwe"){rate = 365; defaultSymbol = "Z$"}
    else if(country === "South Africa"){rate = 20; defaultSymbol = "R"}
    else if(country === "Tanzania"){rate = 2500; defaultSymbol = "Ts"}
    else if(country === "Nigeria"){rate = 1000; defaultSymbol = "N"};
    if (x == '' || x == null) x = 0;
    let conversion = parseFloat(x) * parseFloat(rate)
    x = parseFloat(conversion.toFixed(2))
    return defaultSymbol+x.toLocaleString('en-US');
//return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",");
   }
   catch(e){
    return defaultSymbol+"0";
   } 

   
}

export default moneyFormat