import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"
import { Link, useNavigate } from "react-router-dom"
import requestHandler from "../../utilities/requestHandler"
import { ToastContainer, toast } from 'react-toastify';
import Loading from "../../utilities/Loading"
import Images from "../../utilities/images"
import countriesAndDialCodes from "../../utilities/countries"

const SelectCountry = () => {

    const navigation = useNavigate();
    const [pageTile, setPageTitle] = useState("Select Country")
    const [country, setCountry] = useState(null);
    const [countries, setCountries] = useState(countriesAndDialCodes)
    const disclaimer = "Kindly select your country.";
    const [isLoading, setLoading] = useState(false)
    const [vendors, setVendors] = useState([])


    useEffect(() => {
        document.title = pageTile
    },[pageTile])

    const setCountr = async () => {
        setLoading(true)
        if(country !== null && country !== ""){

        localStorage.setItem('country', country)
        toast.success("Country selected")
        window.location.href = "/" 
    }
    else {
        toast.error("Select a country")
    }
    setLoading(false)
    }

    return(
     
      <div class="row justify-content-center align-items-center">
<div class="col-md-6 col-lg-6 col-xl-6">        
                  <form className="card tooltip-end-top" novalidate>
                    <div className="card-body">
                    <h1>Welcome</h1>
                      <p className="text-alternate mb-4">{disclaimer}</p>
                      <div className="mb-3 filled">
                        <select onChange={e => setCountry(e.target.value)} class="form-control" data-width="100%" id="">
                            <option>Select</option>
                          <option value={country}>{country}</option>
                          {
                            countries.map((x) => {
                            return (<option value={x.country}>{x.country}</option>)  
                            })
                        }
                        </select>
                      </div>
                      
                      <div>
                        {isLoading? <Loading/> : 
                        <button onClick={setCountr} className="btn btn-icon btn-icon-end btn-primary" type="button">
                          <span>Confirm </span>
                          <i className="fa-regular fa-check-circle text-center"></i>
                        </button>}
                      </div>
                      
                    </div>
                     
                    
                  </form>  
                      </div>

                <div class="col-md-6 col-lg-6 col-xl-6 d-none d-lg-block">  
                <img src={Images().landingImage}
          class="img-fluid" alt="Sample image" />
                </div>
                </div>
    )
}

export default SelectCountry;