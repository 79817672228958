import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"
import { Link } from "react-router-dom"

const UnderMaintenance = () => {

    const [pageTile, setPageTitle] = useState("Under Maintenance")
    
    useEffect(() => {
        document.title = pageTile
    },[pageTile])

    return(
        <div className="col">
            <div class="fixed-background"></div>
            <div className="d-flex justify-content-around mt-5 row">
        <div className="card card-body tooltip-end-top h-100">
        <div class="row g-0 h-100">
               <div class="mb-5">
                  {/*<h2 class="cta-1 mb-0 text-primary">Page Not Found!</h2>*/}
                  <h2 class="display-2 text-primary">Service Not Available</h2>
                </div>
                <div class="mb-5">
                  <p class="h6">App is under maintenance. Kindly check back in few minutes.</p>
                  <p class="h6">
                    We are running some updates and we will be back shotly.
                  </p>
    </div>
                {/*<div>
                  <Link href="/" class="btn btn-icon btn-icon-start btn-primary">
                    <i data-cs-icon="arrow-left"></i>
                    <span>Back to Home</span>
                  </Link>
    </div>*/}
              </div>
        </div>
       </div>
       
        </div> 
    )
}

export default UnderMaintenance;