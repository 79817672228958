import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import { ToastContainer, toast } from 'react-toastify';
import Loading from "../../utilities/Loading";
import { Link, useParams } from "react-router-dom";
import requestHandler from "../../utilities/requestHandler";
import bgImage from "../../utilities/images";
import Images from "../../utilities/images";
import countriesAndDialCodes from "../../utilities/countries";


const Register = () => {
    
    const params = new URLSearchParams(window.location.search)
    const {referer} = useParams()
    const [pageTile, setPageTitle] = useState("Registeration")
    const [country, setCountry] = useState(null);
    const [countrySelected, setCountrySelected] = useState(false)
    const [countries, setCountries] = useState(countriesAndDialCodes)
    const [referral, setReferral] = useState(referer);
    const [username, setUsername] = useState('');
    const [email, setEmail] = useState(params.get('email'));
    const [password, setPassword] = useState('');
    const [password_confirmation, setPasswordConfirmation] = useState('');
    const [isLoading, setLoading] = useState(false)
    const [code, setCode] = useState(null)


    const clearFileds = () => {
        //setEmail('')
        setUsername('')
        setPassword('')
        setPasswordConfirmation('')
            }

    useEffect(() => {
        //clearFileds()
        document.title = pageTile
    },[pageTile])

    const setCountr = async () => {
      setLoading(true)
      if(country !== null && country !== ""){
        setCountry(country)
        setCountrySelected(true)
      localStorage.setItem('country', country)
      toast.success("Country selected")
      //window.location.href = "/" 
  }
  else {
      toast.error("Select a country")
  }
  setLoading(false)
  }


    const loginAction = async () => {
        setLoading(true)
        let Data = {email: email, password: password}
        let x = await requestHandler.post('auth/login', Data, false, false)
        if(x != null && x.status === 1){
            //let y = x.message;
            toast.success(x.message)
            //set login token
            localStorage.setItem('access_token', x.access_token)
            localStorage.setItem('account_type', x.account_type)
            window.location.href = "/"
        }
        else if(x != null && x.status === 0){
            toast.error(x.message)
        }
        else{
            toast.error('Something went wrong')
        }
        setLoading(false)
    }


    const regAction = async () => {
        setLoading(true)
        let Data = {username: username, email: email, password: password, password_confirmation: password_confirmation, referral: referral, country: country, code: code}
        let x = await requestHandler.post('auth/register', Data, false, false)
        if(x != null && x.status === 1){
            //let y = x.message;
            toast.success(x.message)
            loginAction()
        }
        else if(x != null && x.status === 0)
        {
            toast.error(x.message)
        }
        else
        {
            toast.error('Something went wrong')
        }
        setLoading(false)
    }

    
    return(
        <div class="col">
<div class="row justify-content-center align-items-center">
  <div class="col-md-6 col-lg-6 col-xl-6"> 
{country !== null && countrySelected? 

<form className="card mb-5 tooltip-end-top" id="loginForm" novalidate>
                    <div className="card-body">
                      {/*<p className="text-alternate mb-4">Please use your credentials to login.</p>*/}
                      <div className="mb-3 filled">
                        <i data-cs-icon="user"></i>
                        <input value={username} onChange={e => setUsername(e.target.value)} className="form-control" placeholder="Username" />
                      </div>
                      <div className="mb-3 filled">
                        <i data-cs-icon="email"></i>
                        <input value={email} onChange={e => setEmail(e.target.value)} className="form-control" placeholder="Email" />
                      </div>
                      <div className="mb-3 filled">
                        <i data-cs-icon="lock-off"></i>
                        <input value={password} onChange={e => setPassword(e.target.value)} className="form-control" type="password" placeholder="Password" />
                      </div>
                      <div className="mb-3 filled">
                        <i data-cs-icon="lock-off"></i>
                        <input value={password_confirmation} onChange={e => setPasswordConfirmation(e.target.value)} className="form-control" type="password" placeholder="Confirm Password" />
                      </div>
                      <div className="mb-3 filled">
                        <i data-cs-icon="mobile"></i>
                        <input value={code} onChange={e => setCode(e.target.value)} className="form-control" placeholder="Coupon code" />
                       </div>
                      <div className="mb-3 filled">
                      <Link to={"/auth/coupon"} target="_blank">Purchase coupon</Link>
                        </div>
                    </div>
                    <div className="card-footer border-0 pt-0 d-flex justify-content-between align-items-center">
                    <Link to="/auth/login" className="me-3">Log In</Link>
                      <div>
                        {isLoading? <Loading/> : 
                        <button onClick={()=>regAction()} className="btn btn-icon btn-icon-end btn-primary" type="button">
                          <span>Register</span>
                          <i data-cs-icon="chevron-right"></i>
                        </button>}
                      </div>
                    </div>
                  </form>

: 

<form className="card tooltip-end-top" >
                      <div className="card-body">
                      <h1>Welcome</h1>
                        <p className="text-alternate mb-4">Kindly select your country.</p>
                        <div className="mb-3 filled">
                          <select onChange={e => setCountry(e.target.value)} class="form-control" data-width="100%">
                              <option>Select</option>
                            {
                              countries.map((x) => {
                              return (<option value={x.country}>{x.country}</option>)  
                              })
                          }
                          </select>
                        </div>
                        
                        <div>
                          {isLoading? <Loading/> : 
                          <button onClick={()=>setCountr()} className="btn btn-icon btn-icon-end btn-primary" type="button">
                            <span>Confirm </span>
                            <i className="fa-regular fa-check-circle text-center"></i>
                          </button>}
                        </div>                        
                      </div>                      
                    </form>

}
</div>
<div class="col-md-6 col-lg-6 col-xl-6 d-none d-lg-block">  
                  <img src={Images().landingImage}
            class="img-fluid" alt="Sample image" />
                  </div>

                <ToastContainer />
                </div>
        </div>
    )
}

export default Register;