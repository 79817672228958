import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"
import Loading from "../../utilities/Loading"
import countriesAndDialCodes from "../../utilities/countries"
import { toast } from "react-toastify"
import moneyFormat from "../../utilities/moneyFormat"

const FAQ = () => {

    const [pageTile, setPageTitle] = useState("FAQ")
    const [country, setCountry] = useState(null);
    const [isLoading, setLoading] = useState(false)

    const faqs = [
        {id: 1, question: "What is CPromoter?", answer: "We are contents promoter. We make money by promoting and sharing contents."},
        {id: 2, question: "Who can share content?", answer: "Only registered members can share content and earn"},
        {id: 3, question: "Is it mandatory to share content?", answer: "Yes, its mandatory. Sharing of content is our major source of income, so everyone must share"},
        {id: 4, question: "What happens if I do not share?", answer: "Your account might be blocked or you may not be able to withdraw"},
        {id: 5, question: "Where should I share contents?", answer: "Contents can be shared on status and to friends social media like Facebook, Whatsapp, Telegram, and Twitter."},
        {id: 6, question: "How much can I earn per content?", answer: "You earn "+moneyFormat(0.3, country)+" when you view a content and "+moneyFormat(0.5, country)+" when you share. In other words, you earn "+moneyFormat(0.8, country)+" in total"},
        //{id: 2, question: "", answer: ""},
        {id: 7, question: "How can I become a vendor?", answer: "Kindly write to us if you want to become a vendor"},
        {id: 8, question: "What is coupon code?", answer: "It is used for registration and it cost just "+moneyFormat(5, country)},
        {id: 9, question: "Do I need to refer before earning?", answer: "No, you can earn by just viewing and sharing contents"},
        {id: 10, question: "Is registration fee refundable?", answer: "No, its not more than "+moneyFormat(5, country)+" and its not refundable"},
        {id: 11, question: "Is there transaction charges on withdrawal?", answer: "Yes, this platform is international and sending money attracts some service charges"},
        {id: 12, question: "What is withdrawal charges?", answer: "Its 35%. 20% for transaction and 15% for admin. In other words, if you withdraw "+moneyFormat(100, country)+", you will get "+moneyFormat(65, country)+""},
        {id: 13, question: "What is minimum withdrawal?", answer: "Minimum withdrawal depends on wallet. For referral wallet, its "+moneyFormat(10, country)+", and main wallet is "+moneyFormat(30, country)},
    ];

    useEffect(() => {
        document.title = pageTile
    },[pageTile])

    const viewFaq = () =>{
        if(country === null || country === ""){
            toast.error("Select your country")
        }
    }

    return(
        <div className="col">
         <h2 class="small-title">Frequently Asked Questions</h2>

          {isLoading? <Loading/> : ''}
        
        <div class="row g-2">

              <div class="card">
              <div class="card-body">

              <div className="mb-3 filled">
                        <select onChange={e => setCountry(e.target.value)} class="form-control" data-width="100%" id="">
                            <option>Select Country</option>
                          {
                            countriesAndDialCodes.map((x) => {
                            return (<option value={x.country}>{x.country}</option>)  
                            })
                          }
                        </select>
                      </div>

<div className="row g-2" style={{display: country === null || country === ""? 'none' : 'flex'}}>
                {faqs.map(x =>
                    <div className="mt-5">
              <h4>{x.question}</h4>
              <div style={{whiteSpace: "pre-wrap"}}>
              {x.answer} 
              </div>
              </div>
                )}
                </div>
                </div>

                </div>

</div>
        </div> 
    )
}

export default FAQ;