import { useEffect, useState } from "react";
import Footer from "../footer";
import Header from "../header";
import LeftMenu from "../leftMenu";
import requestHandler from "../../utilities/requestHandler";
import Loading from "../../utilities/Loading";
import { toast } from "react-toastify";
import TimeInWords from "../../utilities/timeInWords";
import number_format from "../../utilities/number_format";
import truncate from "../../utilities/truncateString";
import { Link } from "react-router-dom";
import dateTimeToDate from "../../utilities/dateTimeToString";
import moneyFormat from "../../utilities/moneyFormat";

const SearchCoupons = () => {

    const [pageTile, setPageTitle] = useState("Coupons")
    
    const [isLoading, setLoading] = useState(false)
    const [data, setData] = useState([])
    const [search, setSearch] = useState(null)
    const [country, setCountry] = useState(null)

    const checkIfLoggedIn = async () => {
        setLoading(true)
        let x = await requestHandler.get('auth/logged/user', true);
        if(x != null && x.status === 0){
            window.location.href = "/auth/login/redirect"
        }
        //userProfile()
        setLoading(false)
      }

      const userProfile = async () => {
        let x = await requestHandler.get('user/profile', true);
        if(x != null && x.status === 1){
            let y = x.message[0];
            setCountry(y.country)
        }
      }

     
      const getCoupons = async () =>{
        let url = 'vendor/'+search+'/coupon';
        setLoading(true)
        let x = await requestHandler.get(url, true)
            if(x != null && x.status === 1)
            {
                setData(x.message)
            }
            else if(x != null && x.status === 0)
            {
                toast.error(x.message)
            }
            else
            {
                toast.error('Something went wrong')
            }
        setLoading(false)
    }
      
    useEffect(() => {
        checkIfLoggedIn()
        document.title = pageTile
    },[pageTile])

    return(
        <div className="col">
             <h2 class="small-title">Coupons</h2>
         
            <form className="card mb-5 tooltip-end-top" novalidate>
                    <div className="card-body">
                      {/*<p className="text-alternate mb-4">Please use your credentials to login.</p>*/}
                      <div className="row">
                      <div className="col">
                      <div className="mb-3">
                        <input value={search} onChange={e => setSearch(e.target.value)} className="form-control" type="text" placeholder="Enter Code" />
                      </div>
                      </div>
                      <div className="col">
                      <div>
                        {isLoading? <Loading/> : 
                        <button onClick={getCoupons} className="btn btn-icon btn-icon-end btn-primary" type="button">
                          <span>Search Coupon</span>
                          <i data-cs-icon="chevron-right"></i>
                        </button>}
                      </div>
                    </div>
                      </div>
                    </div>
                    
                  </form>

            <div class="row row-cols-1 row-cols-sm-2 row-cols-xl-3 g-2 mb-5">

                <table className="table table-striped">
        <thead>
          <tr>
          <th>Date</th>
          <th>Coupon</th>
          {/*<th>Used by</th>*/}
          <th>Status</th>
          </tr>
          </thead>
          <tbody>
      {data.map(x =>         
          <tr>
          <td>{dateTimeToDate(x.created_at)}</td>
          <td>{x.code}</td>
          {/*<td>{x.username}</td>*/}
          <td>{x.status === 0? <span className="text-success">Available</span> : <span className="text-danger">Used</span>}</td>
          </tr>          
      )}
      </tbody>
</table>

        </div>
        {/*isLoading? <Loading/> 
        : 
        <div style={{display: posts.length > 20? 'flex' : 'none'}} class="row mb-5">
          <button onClick={getMoreContents} className="btn btn-primary btn-clock btn-lg">Load More</button>
        </div>
            */}
        </div>  
    )
}

export default SearchCoupons;