import { useEffect, useState } from "react"
import Footer from "../footer"
import Header from "../header"
import LeftMenu from "../leftMenu"
import requestHandler from "../../utilities/requestHandler"
import Loading from "../../utilities/Loading"
import Images from "../../utilities/images"

const ContactUs = () => {

    const [pageTile, setPageTitle] = useState("Contact Us")
    const [about, setAbout] = useState(null);
    const [isLoading, setLoading] = useState(false)
    const [maintain, setMaintain] = useState(null);
    const [title, setTitle] = useState(null);
    const [subTitle, setSubTitle] = useState(null);
    const [logo, setLogo] = useState(null);
    const [logoUrl, setLogoUrl] = useState(null);
    const [url, setUrl] = useState(null);
    const [phone1, setPhone1] = useState(null);
    const [phone2, setPhone2] = useState(null);
    const [email, setEmail] = useState(null);
    const [officeAddress, setOfficeAddress] = useState(null);

    const websiteSettings = async () => {
        setLoading(true)
        let x = await requestHandler.get('settings/get', false);
        if(x != null && x.status === 1){
            x = x.message;
            setTitle(x.title)
                    setSubTitle(x.sub_title)
                    setLogoUrl(x.logo)
                    setUrl(x.url)
                    setAbout(x.about)
                    setPhone1(x.phone1)
                    setPhone2(x.phone2)
                    setEmail(x.email)
                    setOfficeAddress(x.office_address)
                    setMaintain(x.under_maintenance)
        }
        setLoading(false)
      }
    
    useEffect(() => {
        document.title = pageTile
        websiteSettings()
    },[pageTile])

    return(
        <div className="col">
          <h2 class="small-title">{pageTile}</h2>
          {isLoading? <Loading/> : ''}
<div class="row g-2">

<div class="card mb-5">
                <div class="card-body">
                  <div class="d-flex align-items-center flex-column mb-4">
                    <div class="d-flex align-items-center flex-column">
                      <div class="sw-13 position-relative mb-3">
                      <img src={logoUrl !== null? logoUrl :  Images().bgImage} class="img-fluid rounded-xl" alt="thumb" />
                      </div>
                      <div class="h5 mb-0">{title}</div>
                      <div class="text-muted">{subTitle}</div>
                    </div>
                  </div>
                  <div class="nav flex-column" role="tablist">
                    <a class="nav-link px-0 border-bottom border-separator-light" data-bs-toggle="tab" href={"#"} role="tab">
                    <i className="fa-regular fa-map-marker me-2" data-cs-size="17"></i>
                      <span class="align-middle">{officeAddress}</span>
                    </a>
                    <a class="nav-link px-0 border-bottom border-separator-light" data-bs-toggle="tab" href={"mailto:"+email} role="tab">
                    <i className="fa-regular fa-envelope me-2" data-cs-size="17"></i>
                      <span class="align-middle">{email}</span>
                    </a>
                    <a style={{display: phone1 !== null && phone1 !== ""? 'block' : 'none' }} class="nav-link px-0 border-bottom border-separator-light" data-bs-toggle="tab" href={"tel:"+phone1+""} role="tab">
                    <i className="fa-regular fa-phone me-2" data-cs-size="17"></i>
                      <span class="align-middle">{phone1}</span>
                    </a>
                    <a style={{display: phone2 !== null && phone2 !== ""? 'block' : 'none' }} class="nav-link px-0 border-bottom border-separator-light" data-bs-toggle="tab" href={"tel:"+phone2+""} role="tab">
                    <i className="fa-regular fa-phone me-2" data-cs-size="17"></i>
                      <span class="align-middle">{phone2}</span>
                    </a>
                  </div>
                </div>
              </div>

</div>

        </div>  
    )
}

export default ContactUs;